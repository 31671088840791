.icon-text-set {
    font-size: meidum;
    display: flex;
    align-items: center;
  }
  
  .icon-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .icon-link {
    text-decoration: none;
    color: #000;
  }
  
  .icon-text {
    margin: 0;
  }

.contactUs{
    font-size: small;
    width: auto;
}
.followUs{
    font-size: small;
}

@media (max-width: 768px) {
    
    .followUs{ 
        font-size: smaller;
    }

    .icon-text-set {
    font-size: x-small;
    }
    .icon-img {
        width: 20px;
        height: 20px;
    }
    section#gap {
        width: 0px;
    }
}