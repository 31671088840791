*{
  margin: 0px;
}
body{ 
  background-color: white;
}
.App {
  text-align: center;
}
#one.bodyContainer{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  
}
#two.bodyContainer{
  background-image: url('./components/images/newBg.png');
  /* background-size: 60%; */
  /* background-image: url('./components/images/spattern.png'); */
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  background-repeat : 'repeat'; 
  /* background-size: 'auto';  */
  overflow: hidden;
}

@media (max-width: 768px) {
  #two.bodyContainer {
    background-image: url('./components/images/croppedBg.png');
  }
}