#three.bodyContainer {
    background-image: url('./images/newBg.png');
    min-height: 90vh;
    background-color: black;
    background-repeat : repeat; 
    background-size: 'auto'; 
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: 50%; /* Zoom in on the image */
}
  .cardsContainer{
    margin-left: 4%;
    margin-right: 4%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    /* justify-content: center; */
  }

  @media (max-width: 768px) {
    #three.bodyContainer {
      background-size: 100vw auto ;
      background-repeat : repeat-y; 

    }

    .cardsContainer {
      flex-direction: column;
      
    }
  }