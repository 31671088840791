.overlay {
    display: flex;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;

    align-items: center;
    justify-content: center;
}


/* Modal Container Styling */
.download-app-modal {
    display: flex;
    /* background-color: white; */
    /* color: white; */
    background-color: transparent;
    padding: 10px 25px 30px 25px;
    border-radius: 12px; 
    width: 400px;
    max-width: 90%;
    /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); */
    position: relative;
    margin: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 10px; */
}

/* Heading Style */
.download-app-modal h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
}

/* Input Styling */
.download-app-modal input[type="email"],
.download-app-modal select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}



.dropDown{
    display: block;
    overflow-x: hidden;
}


/* Optional Scrollbar Styling for Dropdown */
.download-app-modal select::-webkit-scrollbar {
    width: 8px;
}

.download-app-modal select::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 4px;
}

/* .download-app-modal select::-webkit-scrollbar-track {
    background-color: #f1f1f1;
} */

/* Submit Button Styling */
.download-app-modal button {
    width: auto;
    padding: 8px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 35px;
    font-size: 16px;
    cursor: pointer;
}

/* #submitButton{
    width: 80%;
} */

.download-app-modal button:hover {
    background-color: #000;
}

.submitCloseButtons{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    gap: 10px;
}


.modalHolder{
    display: flex;
    width: auto;
    height: auto;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;

    border: 1px solid rgba(0, 0, 0, 0.5);

    /* background: url("images/glassyTexture.jpg"); */
    
    background: rgba(255, 255, 255, 0.5); 
    backdrop-filter: blur(10px) saturate(150%); 
    /* border: 1px solid rgba(0, 0, 0, 0.5); */
    box-shadow: inset 0px 4px 1000px 1000px rgba(255, 255, 255, 0.4);
}


.modalHolder::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("images/glassytexture.jpg");
    opacity: 0.2; /* Adjust this value to make the texture more or less pronounced */
    pointer-events: none; /* Prevent interaction */
    z-index: -1; /* Send behind the modal content */
    mix-blend-mode: multiply; /* Blend texture with the background */
    border-radius: 20px;
}

