
.footer{
    background-color: white;
    display: flex;
    /* flex-direction: column;
     */
     justify-content: space-around;

    margin: 20px;
    /* margin-left: 10%; */
    
    margin-bottom: 0px;
    padding-bottom: 10px;

    /* align-content: center; */
}
.followUs{
    width: 40%;
    max-width: max-content;
    overflow: auto;
    
    /* border :1px solid red */
}
.contactUs{
    max-width: max-content;
    width: 40%;
    overflow: auto;
    /* border :1px solid red */

}
.socials{
    width: 45%;
    display: flex;
    /* justify-content: left */
    flex-direction: row;
    gap: 100px;

}
.imageContainer {
    width: 100px;   /* Set the width of the container to 30px */
    height: 100px;  /* Set the height of the container to 30px */
    align-content: center;
    text-align: center;
    /* border: 1px solid red; Optional border for visibility */
}

.imageContainer img {
    /* margin: auto;
    margin-top: auto;
    margin-bottom: auto; */
    width: 80px;   /* Set the width of the image to 30px */
    height: 40px;  /* Set the height of the image to 30px */
    scale: 1;
}

.privacyPolicy{
  display: flex;
  width: 35%;
  /* height: 100%; */
  align-items: center;
  /* justify-content: center; */
}



.footerSmall{
    display: none;
}

@media (max-width: 768px) {
  .footer{
    display: none;
  }

  .footerSmall{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .socialsSmall{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    width: 75%;
    margin-left: 5px;
  }

  .socialIconButton{
    background: transparent;
  }

  .imageContainerSmall{
    display: flex;
    width:auto;
    height: 80px;
    align-items: center;
    margin-right: 20px;
    margin-top: 5px;
  }
  
  .imageContainerSmall img{
    width: 60px;
    height: 30px;

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .privacyPolicySmall{
    display: flex;
    /* height: 60px; */
    width: 50%;
    /* height: 100%; */
    font-size: 15px;
    align-items: flex-end;
    padding-bottom: 20px;
    padding-left: 20px;
    /* justify-content: center; */
  }

}
