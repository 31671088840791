.navbar{ 
    background-color: white;
}

#logoName { 
    font-size: larger;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa; /* Example background color */
}

.brand {
    display: flex;
    /* align-items: baseline; */
}


.brand img{
    width: 60px;
    height: 30px;
    margin-right: 20px;
    margin-left: 15px;
}

.logo-link {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    text-decoration: none;
    color: inherit;
}

.navbar-buttons{
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-right: 40px;
}

@media (max-width: 768px) {
    
    #logoName { 
        font-size: medium ;
    }
    .navbar-buttons {
        display: flex;
        flex-direction: row;
        gap: 0px;
        font-size: medium ;
        margin-right: 0px;
        padding: 10px 0px;
    }
    .navbar{
        font-size: 10px;

        
    }
  }

@media (max-width: 668px) {
    #logoName { 
        font-size: 15px ;
    }

    .navbar-buttons {
        font-size: 12px ;
        gap: 1px;
        display: flex;
        flex: row;
        margin-right: 0px;
    }

    .navbar{
        width: 100vw;
        font-size: 10px;
        padding: 10px 0px;
        width: 100%;
    }

    .brand img{
        width: 40px;
        height: 20px;

        margin-right: 15px;

    }
}

@media (max-width: 505px) {
    .navbar{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .navbar-buttons{
        justify-content: space-between;
        width: 100%;
        font-size: 15px;

        padding: 10px 10px 0px 10px;
    }

    /* .brand img{
        width: 0px;
        height: 0px;
    } */

    .brand {
        display: flex;
        flex-direction: row;
        width: 80%;
        justify-content: center;
        padding-top: 0px;
        align-items: baseline;
    }

    .brand img{
        margin-left: 10px;
        margin-right: 10px;

        width: 60px;
        height: 30px;
    }

    #logoName{
        font-size: 25px;
    }


}

