.buttonTextContainer {
    width: 80%; /* Set width to 80% to allow 10% margin on each side */
    /* margin: 0; Center the container and add vertical spacing */
    /* margin: 0px auto; */
    padding: 20px; /* Optional: Add padding inside the container */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center align children */
    justify-content: center; /* Center content vertically */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); Optional: Add shadow for aesthetics */
    border-radius: 8px; /* Rounded corners */
    background-color: white; /* Background color */
}
body{
    /* font-family: "Noah head Heavy"; */
    /* padding-bottom:'1000px'; */
}