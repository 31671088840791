section.bodyContainer{ 
  min-height: 100vh;
} 
#one.bodyContainer{ 
  /* background-image: url('./images/newBg.png'); 
  */
  display: flex;
  justify-content: center;
  /* background-color: white; */
  overflow: hidden;

}
#two.bodyContainer{
  /* background-image: url('./images/newBg.png'); */
  background-color: black;
  /* background-repeat : 'repeat';  */
  /* background-size: 'auto';  */
  overflow: hidden;
}
