#four.bodyContainer{
    padding-top: 30px;
    background-image: url('./images/newBg.png');
    background-color: black;
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    align-items: center;
    /* justify-content: center; */
    /* background-repeat : 'repeat';  */
    /* background-size: 'auto';  */
    overflow: hidden;
  }
  
@media (max-width: 768px) {
    #four.bodyContainer {
      background-size: 100vw auto ;
      background-repeat : repeat-y; 
    }
  }