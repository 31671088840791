@font-face {
    font-family: 'Gotham';
    src: url('../Font/Gotham-Font/Gotham-Black.otf') format('truetype');
    /* Add additional font formats here if needed */
}

section.buttonContainer{
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: yellow;
}
button{ 
    border-radius: 10px;
    /* border: 1px solid red; */
    border: 0px;
    font-family: "Gotham", sans-serif;
}
.button-link {
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for scaling */
    margin-left: 3px;
    border-radius: 31.35px; /* Default border-radius */
  }
  