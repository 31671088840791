.LandinContainer { 
    background-image: url('../images/newBg.png');
    background-repeat : 'repeat'; 
    height: 90vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
    padding-top: 15%;
    /* padding-top: 40px;
    padding-bottom: 40px; */
}


.LandinContainer .above .buttonContainer { 
    width: 100%;
    height: 80%;
    /* background-color: red; */
    text-align: center;
    align-items: center;
}

.LandinContainer .above h3{ 
    color: white;    
    text-align: center;
}
.LandinContainer .above h1{ 
    font-size: 60px;
    text-align: center;
    color: white;
}

.below { 
    margin-bottom: 2%;
}

.below section{ 
    
    text-align: center;
    color: white;
}

@media (max-width: 768px) {
    .above{ 
        height: 10vh;
    }
    .LandinContainer {
        padding-top: 45%;
        
        height: 80vh;
    }
    .below { 
        margin-bottom: 8%;
    }
    .LandinContainer .above h1{ 
        font-size: 50px;
        
    }
  }