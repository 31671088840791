.container {
    display: flex;
    padding-top: 0;
    padding-right: 0;
    padding: 20px; /* Add some padding */
    margin: 0;
    border-radius: 24px; /* Rounded corners */
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); Subtle shadow */
    text-align: center; /* Center align text */
    display: flex; /* Use flexbox for dynamic sizing */
    flex-direction: column; /* Stack children vertically */
    /* justify-content: center; Center items vertically */
    align-items: center; /* Center items horizontally */
    max-width: 80vw; /* Maximum width of the container */
    /* margin: 0 auto; Center the container */
    overflow: hidden; /* Hide overflow to prevent text from spilling out */
}

.title {
    margin-bottom: 15px; /* Space between title and text */
    width: 100%; /* Title takes full width */
}

.title h1 {
    color: white; /* Title color */
    text-align: left; /* Align title text to the left */
    /* font-family: "Gotham Black"; Title font */
    font-size: 38px; /* Title font size */
    font-style: normal; /* Title font style */
    font-weight: 500; /* Title font weight */
    line-height: normal; /* Title line height */
    margin: 0; /* Remove default margin */
}

.text {
    width: 100%; /* Make text container take full width */
    text-align: left; /* Align text to the left */
    color: white; /* Text color */
    /* font-family: "Gotham Light"; Text font */
    font-size: 22px; /* Text font size */
    font-style: normal; /* Text font style */
    font-weight: 500; /* Text font weight */
    line-height: normal; /* Text line height */
    overflow-wrap: break-word; /* Ensure long words break to the next line */
    word-wrap: break-word; /* Fallback for older browsers */
    overflow: hidden; /* Prevent overflow */
    white-space: normal; /* Allow text to wrap normally */
}
